import Vue from "vue";
import VueI18n from 'vue-i18n';
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import vuetify from './plugins/vuetify';
import store from "./store";
import messages from "./locales/common";
import SignupForm from "./components/SignupForm.vue";
import TermsOfUsePage from "./components/TermsOfUsePage.vue";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage.vue";



Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueRouter)


const i18n = new VueI18n({
  locale: 'fr',
  messages
})

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: SignupForm },
    { path: "/conditions-generales-dutilisation", component: TermsOfUsePage },
    { path: "/politique-de-confidentialite", component: PrivacyPolicyPage },
  ]
})

Vue.use(VueGtag, {
  config: { id: "AW-379829578" }
}, router)

new Vue({
  i18n,
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app")
