import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from 'vue';
import Vuetify from 'vuetify';
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        dark: false,
        themes: {
            light: {
                secondary: "#193058", // Dark blue (Use the 'dark' attribute when using as bg color)
                primary: "#2e9cca", // Bright light blue

                // Defaults
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
            },
        },
    },
});
