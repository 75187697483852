export default {
    en: {
        nav: {
            title: "Join",
            home: "Home",
        },
        switchLanguage: "Switch Language"
    },
    fr: {
        nav: {
            title: "Rejoignez",
            home: "Accueil",
        },
        switchLanguage: "Changer de langue"
    }
};