












import { Component, Vue } from "vue-property-decorator";
import store from "../store/";
import InfoForm from "./InfoForm.vue";
import InfoFormModel from "../models/InfoFormModel";

@Component({
  store,
  components: {
    InfoForm
  }
})
export default class SignupForm extends Vue {

  infoForm = new InfoFormModel();
  infoFormValid = true;
}
