




















































































import { Component, Vue } from "vue-property-decorator";
import Language from "./components/Language.vue";
import Signup from "./components/SignupForm.vue";
import termsAndPolicyMessages from "./locales/termsAndPolicy";
import logo from "./assets/TransAtlantis_Logo.png";

@Component({
  components: {
    Language,
    Signup,
  },
  i18n: {
    messages: {
      en: {
        ...termsAndPolicyMessages.en,
      },
      fr: {
        ...termsAndPolicyMessages.fr,
      },
    },
  },
})
export default class App extends Vue {
  logo = logo;

  sidebar = false;

  adWebsiteUrl = "http://indemnisationTR.fr";

  get menuItems() {
    return [
      {
        title: this.$t("nav.home").toString(),
        path: null,
        link: this.adWebsiteUrl,
        icon: "home",
      },
      {
        title: this.$t("nav.title").toString(),
        path: "/",
        link: null,
        icon: "app_registration",
      },
    ];
  }
}
